import { navigate } from 'gatsby';
import React from 'react';
import { Container,} from 'reactstrap';
import Layout from '../../../components/layout'

const ThankYou = (props) => {

    const search = props.location.search;
    const params = new URLSearchParams(search);
    const link = params.get('redirect');
    const redirectTitle = params.get('redirectTitle')

    const gotoBlog = () => {
        navigate(link)
    }
    return (
        <Layout>
           <section className="thank_you_section">
               <Container>
                   <h1>THANK YOU FOR YOUR INTEREST!</h1>
                   <h2>We will get back to you soon </h2>
                   <button onClick={gotoBlog}>{redirectTitle}</button>
               </Container>
           </section>
        </Layout>


    );
}

export default ThankYou;